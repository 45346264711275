import React, {useState} from 'react';
import axios from "axios";

function UploadFile() {
    const [listing, updateListing] = useState(false)

    async function fetchData() {
        try {
            await axios.get("http://adminlegit/list/json-list").then(function (response) {
                console.log(response.data)
                updateListing(response.data)
            });
        } catch (error) {
            console.error(error);
        }
    }

    fetchData()

    return (
        <div>
            <h1>this is the homepage</h1>
            <p>{listing}</p>
        </div>


    );
}

export default UploadFile;