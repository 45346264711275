/* src/App.js */
import './App.css'
import UploadFile from "./pages/UploadFile";
import React, { useState, useEffect } from 'react'
import { create, urlSource  } from 'ipfs-http-client'
import axios from 'axios';
import {Buffer} from 'buffer';
import ProgressBar from 'react-bootstrap/ProgressBar';

const projectId = "2DAKc2jkKVEQagih9xQxIZxb6y5";
const projectSecret = "1eed35c39713b0b6c3453f17db7faf1c";
const auth = 'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');
// const client = create({'https://ipfs.infura.io:5001/api/v0/')

const client = create({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    // uri:'https://ipfs.infura.io:5001',
    headers: {
        authorization: auth,
    },
});

function App() {

    const [fileUrl, updateFileUrl] = useState(``)
    const [fileVid, updateFileVid] = useState(``)
    const [message, setMessage] = useState('');
    const [finalUrl, updateFinalUrl] = useState({})

    const [newImg, updateNewImg] = useState(``)
    const [newVid, updateNewVid] = useState(``)
    const [username, updateUsername] = useState(``)
    const [password, updatePassword] = useState(``)
    const [showResults, setShowResults] = useState(false)
    const [showLogin, setShowLogins] = useState(true)
    const [showLink, setShowLink] = useState(false)
    const [showProgress, setShowProgress] = useState(false)
    const [urlImg, updateUrlImg] = useState(false)
    const [arrUrl, updateArrUrl] = useState('')
    const [now, updateNow] = useState('')
    const [max, updateMax] = useState('')
    const [number, updateNr] = useState('')
    var num = 0;



  async function onChange(e) {

    const file = e.target.files[0]

    try {
      const added = await client.add(file)

      const url = `https://ALWAYSLEGIT.infura-ipfs.io/ipfs/${added.path}`
      updateFileUrl(url)

    } catch (error) {
      console.log('Error uploading file: ', error)
    }

  }
    async function onChangeVid(e) {

        const file = e.target.files[0]
        console.log(file);
        try {
            const added = await client.add(file)

            const url = `https://ALWAYSLEGIT.infura-ipfs.io/ipfs/${added.path}`
            updateFileVid(url)
            console.log(url)
        } catch (error) {
            console.log('Error uploading file: ', error)
        }
    }



    const handleChange = event => {
        setMessage(event.target.value);
        updateArrUrl("https://admin.alwayslegit.com/list/json-listing?id="+event.target.value)
    };

    const handleClick = event => {
        event.preventDefault();
        if (message === ""){
            alert("listing ID is required")
        }else if(fileVid ===""){
            alert("Please wait!")
        }else if(fileUrl ===""){
            alert("Please wait!")
        } else {
            const params = new URLSearchParams();
            params.append('listingId', message);
            params.append('url', fileUrl);
            params.append('urlVid', fileVid);

            axios.post('https://www.alwayslegit.com/listing/uploadIpfs', params).then(function(response) {
                console.log(response);
                if (response.data[0] === 'notImg'){
                    alert('Image is required!')
                }else if(response.data[0] === 'notVid'){
                    alert('Video is required!')
                }else{
                    console.log(response.data[0])

                    sendUrl(JSON.stringify(response.data[0]),message)
                }
            });
        }
    };


    async function urlClick(e) {

        if (message.includes(',')){
            var dataArray = message.split(",");
            var percent = 0;
            updateNow(percent)
            var max = dataArray.length;

            return Promise.all(
                dataArray.map(async item => {
                    updateNewImg(item)

                    await axios.post(`https://www.alwayslegit.com/listing/ipfs/${item}`).then(function(response) {
                        console.log(response);
                        var image = response.data[0]['image'];
                        var video = response.data[0]['animation_url'];
                        if (video == null){
                            video = '';
                        }
                        if (image == null){
                            image ='';
                        }
                        callerFun(image,video,item,max);
                    });
                })

            ).then(() => {
                console.log('Items processed');
            });


            // for (var i = 0; i<dataArray.length; i++){
            //     setMessage(dataArray[i])
            //     // console.log(message);
            //    await axios.post(`http://alwayslegit/listing/ipfs/${dataArray[i]}`).then(function(response) {
            //         console.log(response);
            //         var image = response.data[0]['image'];
            //         var video = response.data[0]['animation_url'];
            //         if (video == null){
            //             video = '';
            //         }
            //         if (image == null){
            //             image ='';
            //         }
            //         setTimeout(function () {
            //             callerFun(image,video);
            //         },500000)
            //
            //     });
            // }
        }else {
            await axios.post(`https://www.alwayslegit.com/listing/ipfs/${message}`).then(function(response) {
                console.log(response);
                var image = response.data[0]['image'];
                var video = response.data[0]['animation_url'];
                if (video == null){
                    video = '';
                }
                if (image == null){
                    image ='';
                }
                callerFun(image,video,message,1);
            });
        }
    }

    async function sendUrl(e,item,max) {

        try {
            const uri = await client.add(e)

            const url = `https://ALWAYSLEGIT.infura-ipfs.io/ipfs/${uri.path}`
            const params = new URLSearchParams();
            params.append('listingId', item);
            params.append('url', url);
            updateFinalUrl(url)
            axios.post('https://www.alwayslegit.com/listing/linkIpfs', params).then(function(response) {
                // axios.post('http://alwayslegit/listing/linkIpfs', params).then(function(response) {

                //     console.log(response);
                // if (response.data[0] == 'success'){
                //     alert("Success")
                // }
                num = num +1;
                console.log(num)
                updateNr(num)
                var percent = num * 100 / max;
                console.log(percent);
                updateNow(Math.round(percent))
            });
            setShowLink(true)
            setShowProgress(true)
        } catch (error) {
            console.log('Error uploading file: ', error)
        }
    }

    const loginUser = event => {

        const email = event.target.value;
        updateUsername(email);
        console.log(email);
        // setMessage(event.target.value);
    };
    const loginPass = event => {
        const pass = event.target.value;
        updatePassword(pass);
    };

    function testAsync(u,v,item,max){


        return new Promise((resolve,reject)=>{
            console.log(item)

            const params =  new URLSearchParams();
            params.append('listingId', item);
            params.append('url', u);
            params.append('urlVid', v);

             axios.post('https://www.alwayslegit.com/listing/uploadIpfs', params).then(function(response) {
                 // axios.post('http://alwayslegit/listing/uploadIpfs', params).then(function(response) {

                // console.log(response.data[0])
                sendUrl(JSON.stringify(response.data[0]),item,max)
            });
            resolve();
        });

    }



    async function callerFun(img,vid,item,max){
        console.log("Caller");
        const file = await axios.get(img, { responseType: 'blob' })
            .then(response => {
                console.log(response.data);
                return new File([response.data], "dummy");
            })
            .catch(ex => {
                console.error(ex);
            });

        const fileVideo = await axios.get(vid, { responseType: 'blob'})
            .then(response => {
                console.log(response.data);
                return new File([response.data], "dummyVideo");
            })
            .catch(ex => {
                console.error(ex);
            });

        try {

            const uriImg = await client.add(file)
            var url = `https://ALWAYSLEGIT.infura-ipfs.io/ipfs/${uriImg.path}`;

            const uriVid = await client.add(fileVideo)
            var urlVid = `https://ALWAYSLEGIT.infura-ipfs.io/ipfs/${uriVid.path}`;

        } catch (error) {
            console.log('Error uploading file: ', error)
        }
        await testAsync(url,urlVid,item,max);
    }

    function checkUser(e){
        e.preventDefault();
        if (username === 'admin' && password === 'uploadIpfs'){
            setShowResults(true);
            setShowLogins(false);
        }
    }
    const downloadJson = event => {
        setShowProgress(false)
    };
    const Results = () => (
        <div>
            <h1>IPFS Upload</h1>
            <div>
                <label>Upload Image</label>
                <input type="file" onChange={onChange}/>
            </div>

            <div>
                <label>Upload Video</label>
                <input type="file" onChange={onChangeVid}/>
            </div>
            <div>
                <label>Listing ID</label>
                <input type="text" id="message" name="message" onChange={handleChange} value={message} autoComplete="off"/>
            </div>
            <div>
                <button onClick={handleClick}>Submit</button>
                <button onClick={urlClick}>Create Uri</button>
            </div>
            { showLink ? <UriLink /> : null }
        </div>
    )
    const UriLink = () => (
        <div>
            <a onClick={downloadJson} target="_blank" href={arrUrl}>Download JSON</a>

        </div>
    )

    const Progress = () => (
        <ProgressBar className="orange-progress-bar" now={now} label={`${now}%`} />
    )



    return (

      <div className="App">
          {/*{ termsAccepted ? <Results /> : null }*/}
          {/*{ showLogin && (*/}
          {/*    <div className="loginDiv">*/}
          {/*        <h1>LOGIN</h1>*/}
          {/*        <div>*/}
          {/*            <div>*/}
          {/*                <label>Username</label>*/}
          {/*                <input type="text" name="username" onChange={loginUser}/>*/}
          {/*            </div>*/}
          {/*            <div>*/}
          {/*                <label>Password</label>*/}
          {/*                <input type="password" name="paswword" onChange={loginPass}/>*/}
          {/*            </div>*/}
          {/*            <button onClick={checkUser}>LOGIN</button>*/}
          {/*        </div>*/}
          {/*    </div> )*/}

          {/*}*/}

          <div className="uploadDiv">
              <h1>IPFS Upload</h1>
              <div>
                  <label>Upload Image</label>
                  <input type="file" onChange={onChange}/>
              </div>

              <div>
                  <label>Upload Video</label>
                  <input type="file" onChange={onChangeVid}/>
              </div>
              <div>
                  <label>Listing ID</label>
                  <input type="text" id="message" name="message" onChange={handleChange} value={message} autoComplete="off"/>
              </div>
              <div>
                  <button onClick={handleClick}>Submit</button>
                  <button onClick={urlClick}>Create Uri</button>
              </div>
              { showLink ? <UriLink /> : null }
              { showProgress ? <Progress /> : null }
          </div>
      </div>
  );
}

export default App